import React, { Component } from "react";
import "./Home.css";
import geekyTapTapLogo from "../../assets/geeky-tap-tap-logo.png";
import mechTradeLogo from "../../assets/mech-trade-logo.png";
import sweatTimeLogo from "../../assets/sweat-time-logo.png";

class Home extends Component {
  state = {};
  render() {
    return (
      <div className="container">
        <hr />
        <div className="row">
          <div className="col text-center sub-header">
            A product and services think tank
          </div>
        </div>

        {/* Apps */}
        <div className="row text-center pt-3">
          <div className="col text-center">
            <h3>Apps</h3>
          </div>
        </div>

        <div className="row text-center">
          <div className="col-lg-4 mb-5 mb-lg-0 p-3">
            <a
              href="https://apps.apple.com/us/app/sweattime/id1515823955"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="icon mb-4"
                src={sweatTimeLogo}
                alt="SweatTime Watch app"
              />
            </a>
            <span className="icon-title">SweatTime</span>
            <p className="mb-0 icon-sub-title">Apple Watch app</p>
          </div>
        </div>

        {/* Consulting */}
        <div className="row text-center pt-3">
          <div className="col text-center">
            <h3>Consulting</h3>
          </div>
        </div>

        <div className="row text-center">
          <div className="col-lg-4 mb-5 mb-lg-0 p-3">
            <a
              href="https://www.geekytaptap.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="icon mb-4"
                src={geekyTapTapLogo}
                alt="Geekytaptap Consulting"
              />
            </a>
            <span className="icon-title">Geekytaptap</span>
            <p className="mb-0 icon-sub-title">Design / Software Consulting</p>
          </div>
        </div>

        {/* Marketplaces */}
        <div className="row text-center pt-3">
          <div className="col text-center">
            <h3>Marketplaces</h3>
          </div>
        </div>

        <div className="row text-center">
          <div className="col-lg-4 mb-5 mb-lg-0 p-3">
            <a
              href="https://www.mechtrade.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="icon mb-4" src={mechTradeLogo} alt="MechTrade" />
            </a>
            <span className="icon-title">MechTrade</span>
            <p className="mb-0 icon-sub-title">Mechanical Keyboards / Parts</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
