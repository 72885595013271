import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import mainLogo from "./assets/smpl-inc-logo-small.png";
import Home from "./components/home/Home";
import Privacy from "./components/privacy/Privacy";

function App() {
  return (
    <Router>
      <Link to="/">
        <img className="main-logo" src={mainLogo} alt="SMPL Inc." />
      </Link>
      <Switch>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
